<template>
  <v-dialog :value="isOpen" fullscreen persistent width="100%">
    <v-card class="h-100 w-100">
      <page-title title="Setup your account" />
      <v-row :align="`center`" no-gutters style="height: 100vh">
        <v-col v-if="$vuetify.breakpoint.width >= 1050" class="h-100" cols="6">
          <FeatureCarousel />
        </v-col>

        <v-col class="h-100">
          <v-row :align="`center`" justify="center" style="height: 100%">
            <v-col cols="2"></v-col>
            <v-col cols="8">
              <div>
                <v-img
                  src="@/assets/img/brand/Symbo_Icon_Primary.svg"
                  height="50"
                  width="50"
                  class="mb-3"
                />
                <span
                  class="pt-3 font-thiccboi font-weight-bold"
                  :style="
                    heightGreaterThan850
                      ? 'font-size: 2.0em'
                      : 'font-size: 1.5em'
                  "
                >
                  Setup your account
                </span>

                <v-form
                  ref="accountSetupForm"
                  class="pt-4"
                  style="max-width: 600px"
                >
                  <v-text-field
                    v-model="orgName"
                    dense
                    outlined
                    label="Your org's name"
                    :rules="[(v) => !!v || 'Org name required']"
                  />
                  <v-text-field
                    v-model="firstName"
                    dense
                    outlined
                    label="First name"
                    :rules="[(v) => !!v || 'First name required']"
                  />
                  <v-text-field
                    v-model="lastName"
                    dense
                    outlined
                    label="Last name"
                    :rules="[(v) => !!v || 'Last name required']"
                  />
                  <timezone-select
                    v-model="timezone"
                    useString
                    label="Timezone"
                  />
                </v-form>

                <div class="d-flex align-center w-100 mt-8 pr-3">
                  <v-btn
                    color="grey darken-1"
                    class="white--text"
                    @click="
                      logout()
                      $router.push('/login')
                    "
                  >
                    <v-icon size="22" class="mr-2">mdi-logout</v-icon>
                    Logout
                  </v-btn>

                  <v-btn
                    color="green"
                    class="white--text ml-3"
                    :loading="saveInfoLoader"
                    @click="completeSetup()"
                  >
                    <v-icon size="22" class="mr-2">mdi-check-bold</v-icon>
                    Confirm
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FeatureCarousel from '@/components/user-signup/FeatureCarousel.vue'
import TimezoneSelect from '@/components/utilities/TimezoneSelect.vue'

import UserApi from '@/services/api/Users'
import OrgApi from '@/services/api/Organization'

import { UserSettings } from '@/constants'

import { DateTime } from 'luxon'

export default {
  name: 'AccountSetup',
  components: {
    FeatureCarousel,
    TimezoneSelect,
  },
  data: () => ({
    orgName: '',
    firstName: '',
    lastName: '',
    timezone: '',
    formInvalid: false,
    saveInfoLoader: false,
  }),
  computed: {
    ...mapGetters('user', ['signupIncomplete', 'organization', 'user']),
    isOpen() {
      return this.signupIncomplete
    },
    heightGreaterThan850() {
      return this.$vuetify.breakpoint.height >= 850
    },
  },
  methods: {
    ...mapActions('user', ['logout', 'updateSetting', 'load']),
    validateForm() {},
    completeSetup() {
      if (!this.$refs.accountSetupForm.validate()) {
        this.$store.commit('snackbar/setSnack', {
          snack: 'Please fill in the required information.',
          snackType: 'warning',
        })
        return
      } else {
        console.log('SAVE USER INFO')

        this.saveInfoLoader = true

        Promise.all([
          // Add first and last name on the user
          UserApi.updateUserProfile({
            first_name: this.firstName,
            last_name: this.lastName,
          }),
          // Add organization name
          OrgApi.updateOrg(this.organization.id, {
            name: this.orgName,
          }),
          // Setting timezone in user settings
          this.updateSetting({
            userId: this.user.id,
            settings: {
              settings_key: UserSettings.TIMEZONE,
              settings_value: this.timezone,
            },
          }),
        ])
          .then(() => location.reload())
          .finally(() => (this.saveInfoLoader = false))
      }
    },
  },
  created() {
    this.timezone = DateTime.now().zoneName
    this.firstName = this.user.first_name
    this.lastName = this.user.last_name
    this.orgName = this.organization.name
  },
}
</script>
