<template>
  <v-select
    v-model="timezoneLocal"
    dense
    outlined
    hide-details
    item-text="label"
    item-value="value"
    :disabled="disabled"
    :return-object="!useString"
    :items="availableTimezones"
    :label="label"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :rules="[!!timezoneLocal]"
  ></v-select>
</template>

<script>
import { mapGetters } from 'vuex'

import { timeZonesNames } from '@vvo/tzdb'

const usTZGroups = [
  {
    label: 'Eastern Time',
    value: 'America/New_York',
  },
  {
    label: 'Central Time',
    value: 'America/Chicago',
  },
  {
    label: 'Mountain Time (America/Denver)',
    value: 'America/Denver',
  },
  {
    label: 'Pacific Time',
    value: 'America/Los_Angeles',
  },
  {
    label: 'Alaska Time',
    value: 'America/Anchorage',
  },
]

export default {
  props: {
    value: {
      required: true,
    },
    useString: {
      type: Boolean,
      default: false,
    },
    prospectTimezone: {
      type: String,
    },
    prependIcon: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('user', ['user', 'getSettingByKey']),

    availableTimezones() {
      // Need to get all timezones, but should display the user timezone first
      // and the prospect timezone second
      // The labels should indicate if the timezone is the user timezone or the
      // prospect timezone.
      // There should be a separator between these timezones and all the others.
      const allTimezones = timeZonesNames
      const userTimezone = this.getSettingByKey('timezone')

      allTimezones.splice(allTimezones.indexOf(userTimezone), 1)
      if (this.prospectTimezone) {
        allTimezones.splice(allTimezones.indexOf(this.prospectTimezone), 1)
      }
      const timezoneObjects = allTimezones.map((timezone) => ({
        value: timezone,
        label: timezone,
      }))
      timezoneObjects.unshift({ divider: true }, { header: 'All timezones' })
      if (this.prospectTimezone && this.prospectTimezone === userTimezone) {
        timezoneObjects.unshift(
          { header: 'Quick timezones' },
          {
            label: `${this.prospectTimezone} (Shared)`,
            value: this.prospectTimezone,
          },
          ...usTZGroups
        )
        return timezoneObjects
      }
      if (this.prospectTimezone)
        timezoneObjects.unshift({
          label: `${this.prospectTimezone} (Prospect)`,
          value: this.prospectTimezone,
        })
      timezoneObjects.unshift(
        { header: 'Quick timezones' },
        ...(userTimezone
          ? [
              {
                label: `${userTimezone} (Yours)`,
                value: userTimezone,
              },
            ]
          : []),
        ...usTZGroups
      )
      return timezoneObjects
    },
    timezoneLocal: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  created() {
    if (!this.timezoneLocal) this.timezoneLocal = this.availableTimezones[1]
  },
}
</script>

<style scoped></style>
