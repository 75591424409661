<template>
  <div class="slider">
    <v-carousel cycle interval="3000" continuous height="100%">
      <!-- Local presence -->
      <v-carousel-item>
        <v-sheet
          class="d-flex flex-column align-center py-8 justify-center"
          height="100%"
          color="#1241EB"
        >
          <div style="width: 50%; min-width: 500px" class="h-100">
            <!-- Illustration -->
            <div class="h-60">
              <div class="d-flex align-center white--text">
                <v-avatar size="32px" color="#88E6FF" class="mr-2">
                  <v-icon color="#1241EB">mdi-map-marker</v-icon>
                </v-avatar>
                <div class="text-body-2 font-weight-bold">
                  LOCAL PRESENCE DIALING
                </div>
              </div>
              <v-card
                color="rgba(0, 0, 0, 0.3)"
                class="pa-6 mt-4 elevation-0 rounded-lg"
                height="calc(100% - 50px)"
              >
                <v-img
                  height="100%"
                  contain
                  src="@/assets/img/illustrations/local-presence.svg"
                />
              </v-card>
            </div>

            <div class="h-40">
              <!-- Text -->
              <div class="pt-8 white--text w-100">
                <div class="text-h3 mb-4 font-weight-bold">
                  Call People with Local Phone Numbers
                </div>

                <div>
                  Sales calls that feel local because your number automatically
                  matches their area code
                </div>
              </div>

              <!-- Marker chips -->
              <div
                class="d-flex align-center font-weight-bold w-100 mt-8"
                style="color: #1138bd; font-size: 24px"
              >
                <v-chip color="#FCDD1A" class="mr-3 py-5 px-4 rounded-24">
                  3x Improved Answer Rates
                </v-chip>
                <v-chip color="#95E8FF" class="py-5 px-4 rounded-24">
                  300+ Area Codes Included
                </v-chip>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>

      <!-- Power dialing -->
      <v-carousel-item>
        <v-sheet
          class="d-flex flex-column align-center py-8 justify-center"
          height="100%"
          color="#1241EB"
        >
          <div style="width: 50%; min-width: 500px" class="h-100">
            <!-- Illustration -->
            <div class="h-60">
              <div class="d-flex align-center white--text">
                <v-avatar size="32px" color="#88E6FF" class="mr-2">
                  <v-img
                    contain
                    height="20px"
                    src="@/assets/img/icons/power-dial-icon.svg"
                  />
                </v-avatar>
                <div class="text-body-2 font-weight-bold">POWER DIALING</div>
              </div>
              <v-card
                color="rgba(0, 0, 0, 0.3)"
                class="pa-6 mt-4 elevation-0 rounded-lg"
                height="calc(100% - 50px)"
              >
                <v-img
                  height="100%"
                  contain
                  src="@/assets/img/illustrations/pd-illustration.svg"
                />
              </v-card>
            </div>

            <div class="h-40">
              <!-- Text -->
              <div class="pt-8 white--text w-100">
                <div class="text-h3 mb-4 font-weight-bold">
                  Call More People per Hour
                </div>

                <div>
                  <ul>
                    <li>Maximise connections & minimise delays</li>
                    <li>Automatically use local numbers</li>
                    <li>Live transcription & auto voicemail drop</li>
                    <li>Manage & prioritize calling lists</li>
                  </ul>
                </div>
              </div>

              <!-- Marker chips -->
              <div
                class="d-flex align-center font-weight-bold w-100 mt-8"
                style="color: #1138bd; font-size: 24px"
              >
                <v-chip color="#FCDD1A" class="mr-3 py-5 px-4 rounded-24">
                  4 Simultaneous Dials
                </v-chip>
                <v-chip color="#95E8FF" class="py-5 px-4 rounded-24">
                  Predictive Dialing
                </v-chip>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>

      <!-- CRM Integration -->
      <v-carousel-item>
        <v-sheet
          class="d-flex flex-column align-center py-8 justify-center"
          height="100%"
          color="#1241EB"
        >
          <div style="width: 50%; min-width: 500px" class="h-100">
            <!-- Illustration -->
            <div class="h-55">
              <div class="d-flex align-center white--text">
                <v-avatar size="32px" color="#88E6FF" class="mr-2">
                  <v-icon color="#1241EB">mdi-phone</v-icon>
                </v-avatar>
                <div class="text-body-2 font-weight-bold">
                  CRM DIALER INTEGRATION
                </div>
              </div>
              <v-card
                color="rgba(0, 0, 0, 0.3)"
                class="pa-6 mt-4 elevation-0 rounded-lg"
                height="calc(100% - 50px)"
              >
                <v-img
                  height="100%"
                  contain
                  src="@/assets/img/illustrations/hubspot-integrated-dialer.svg"
                />
              </v-card>
            </div>

            <div class="h-45">
              <!-- Text -->
              <div class="pt-8 white--text w-100">
                <div class="text-h3 mb-4 font-weight-bold">
                  One Click to Call Your CRM Contacts
                </div>

                <div>
                  Streamline calls from CRM with a seamless integration for
                  click-to-call and call logging
                </div>
                <div class="d-flex align-center mt-3" style="gap: 6px">
                  <v-chip
                    small
                    label
                    class="font-weight-bold d-flex align-center px-2"
                    :color="getCrmLightColor('hubspot')"
                  >
                    <v-icon small class="mr-1" :color="getCrmColor('hubspot')">
                      {{ getCrmIcon('hubspot') }}
                    </v-icon>
                    HubSpot
                  </v-chip>
                  <v-chip
                    small
                    label
                    class="font-weight-bold d-flex align-center px-2"
                    :color="getCrmLightColor('salesforce')"
                  >
                    <v-icon
                      small
                      class="mr-1"
                      :color="getCrmColor('salesforce')"
                    >
                      {{ getCrmIcon('salesforce') }}
                    </v-icon>
                    Salesforce
                  </v-chip>
                  <v-chip
                    small
                    label
                    class="font-weight-bold d-flex align-center px-2"
                    :color="getCrmLightColor('gohighlevel')"
                  >
                    <v-icon
                      small
                      class="mr-1"
                      :color="getCrmColor('gohighlevel')"
                    >
                      {{ getCrmIcon('gohighlevel') }}
                    </v-icon>
                    GoHighLevel
                  </v-chip>
                  <v-chip
                    small
                    label
                    class="font-weight-bold d-flex align-center px-2"
                    :color="getCrmLightColor('pipedrive')"
                  >
                    <v-icon
                      small
                      class="mr-1"
                      :color="getCrmColor('pipedrive')"
                    >
                      {{ getCrmIcon('pipedrive') }}
                    </v-icon>
                    Pipedrive
                  </v-chip>
                </div>
              </div>

              <!-- Marker chips -->
              <div
                class="d-flex align-center font-weight-bold w-100 mt-8"
                style="color: #1138bd; font-size: 24px"
              >
                <v-chip color="#FCDD1A" class="mr-3 py-5 px-4 rounded-24">
                  Local Presence in CRM
                </v-chip>
                <v-chip color="#95E8FF" class="py-5 px-4 rounded-24">
                  Recording & Transcription
                </v-chip>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>

      <!-- Analytics -->
      <v-carousel-item>
        <v-sheet
          class="d-flex flex-column align-center py-8 justify-center"
          height="100%"
          color="#1241EB"
        >
          <div style="width: 50%; min-width: 500px" class="h-100">
            <!-- Illustration -->
            <div class="h-60">
              <div class="d-flex align-center white--text">
                <v-avatar size="32px" color="#88E6FF" class="mr-2">
                  <v-img
                    contain
                    height="18px"
                    src="@/assets/img/icons/trend.svg"
                  />
                </v-avatar>
                <div class="text-body-2 font-weight-bold">
                  ANALYTICS & CALL COACHING
                </div>
              </div>
              <v-card
                color="rgba(0, 0, 0, 0.3)"
                class="pa-6 mt-4 elevation-0 rounded-lg"
                height="calc(100% - 50px)"
              >
                <v-img
                  height="100%"
                  contain
                  src="@/assets/img/illustrations/analytics-illustration.svg"
                />
              </v-card>
            </div>

            <div class="h-40">
              <!-- Text -->
              <div class="pt-8 white--text w-100">
                <div class="text-h3 mb-4 font-weight-bold">
                  Performance Analytics for Sales Teams
                </div>

                <div>
                  Utilize comprehensive data to elevate your team's sales
                  tactics, boost efficiency, and enhance overall performance
                </div>
              </div>

              <!-- Marker chips -->
              <div
                class="d-flex align-center font-weight-bold w-100 mt-8"
                style="color: #1138bd; font-size: 24px"
              >
                <v-chip color="#FCDD1A" class="mr-3 py-5 px-4 rounded-24">
                  Live Call Coaching
                </v-chip>
                <v-chip color="#95E8FF" class="py-5 px-4 rounded-24">
                  AI Call Insights
                </v-chip>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>

      <!-- Multi channel outreach -->
      <v-carousel-item>
        <v-sheet
          class="d-flex flex-column align-center py-8 justify-center"
          height="100%"
          color="#1241EB"
        >
          <div style="width: 50%; min-width: 500px" class="h-100">
            <!-- Illustration -->
            <div class="h-60">
              <div class="d-flex align-center white--text">
                <v-avatar size="32px" color="#88E6FF" class="mr-2">
                  <v-img
                    contain
                    height="18px"
                    src="@/assets/img/icons/trend.svg"
                  />
                </v-avatar>
                <div class="text-body-2 font-weight-bold">ACTIONS IN SYMBO</div>
              </div>
              <v-card
                color="rgba(0, 0, 0, 0.3)"
                class="pa-6 mt-4 elevation-0 rounded-lg"
                height="calc(100% - 50px)"
              >
                <v-img
                  height="100%"
                  contain
                  src="@/assets/img/illustrations/channel-outreach.svg"
                />
              </v-card>
            </div>

            <div class="h-40">
              <!-- Text -->
              <div class="pt-8 white--text w-100">
                <div class="text-h3 mb-4 font-weight-bold">
                  Multi-Channel Outreach
                </div>

                <ul>
                  <li>Connect with prospects via email, calls, and LinkedIn</li>
                  <li>
                    Streamline your daily actions for enhanced productivity
                  </li>
                  <li>Ensure consistent follow-up with every prospect</li>
                </ul>
              </div>

              <!-- Marker chips -->
              <div
                class="d-flex align-center font-weight-bold w-100 mt-8"
                style="color: #1138bd; font-size: 24px"
              >
                <v-chip color="#FCDD1A" class="mr-3 py-5 px-4 rounded-24">
                  Calendar Scheduler
                </v-chip>
                <v-chip color="#95E8FF" class="py-5 px-4 rounded-24">
                  Progressive Action Flow
                </v-chip>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import {
  getCrmIcon,
  getCrmColor,
  getCrmLightColor,
} from '@/constants/CrmOptions.js'

export default {
  name: 'FeatureCarousel',
  methods: {
    getCrmColor,
    getCrmIcon,
    getCrmLightColor,
  },
}
</script>
<style>
.slider {
  background-color: #1241eb;
  max-width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.flip {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
</style>
