<template>
  <v-dialog :value="isOpen" fullscreen persistent width="100%">
    <v-card class="h-100 w-100">
      <page-title title="Email verification required" />
      <v-row :align="`center`" no-gutters style="height: 100vh">
        <v-col v-if="$vuetify.breakpoint.width >= 1050" class="h-100" cols="6">
          <FeatureCarousel />
        </v-col>

        <v-col class="h-100">
          <v-row :align="`center`" justify="center" style="height: 100%">
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <div>
                <v-img
                  src="@/assets/img/brand/Symbo_Icon_Primary.svg"
                  height="50"
                  width="50"
                  class="mb-3"
                />
                <span
                  class="pt-3 font-thiccboi font-weight-bold"
                  :style="
                    heightGreaterThan850
                      ? 'font-size: 2.0em'
                      : 'font-size: 1.5em'
                  "
                >
                  Email Address Verification
                </span>
                <div
                  class="pt-2 pb-10 px-1 grey--text text--darken-4"
                  :style="
                    heightGreaterThan850
                      ? 'font-size: 1.2em'
                      : 'font-size: 1.0em'
                  "
                >
                  Verify your email address by clicking the link sent to your
                  email address.

                  <br /><br />If you did not receive the email:

                  <ul class="mt-2">
                    <li>Please check your spam folder</li>
                    <li>Try resending the email</li>
                    <li>
                      <span
                        class="link font-weight-bold"
                        @click="contactSupport"
                      >
                        Contact support
                      </span>
                    </li>
                  </ul>
                </div>

                <v-text-field
                  v-model="otp"
                  dense
                  hide-details
                  outlined
                  label="Enter OTP"
                  class="mb-6"
                />

                <v-btn
                  color="green"
                  class="white--text"
                  :disabled="!otp"
                  :loading="verifyOTPLoader"
                  @click="verifyOTP"
                >
                  <v-icon size="22" class="mr-2">mdi-check-bold</v-icon>
                  Verify OTP
                </v-btn>

                <v-btn
                  color="primary"
                  class="ml-3"
                  :loading="resendEmailLoader"
                  @click="resendEmail"
                >
                  <v-icon size="22" class="mr-2">mdi-refresh</v-icon>
                  Resend email
                </v-btn>
                <v-btn
                  color="grey darken-1"
                  class="white--text ml-3"
                  @click="
                    logout()
                    $router.push('/login')
                  "
                >
                  <v-icon size="22" class="mr-2">mdi-logout</v-icon>
                  Logout
                </v-btn>
              </div>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FeatureCarousel from '@/components/user-signup/FeatureCarousel.vue'

import { EventBus } from '@/services/event-bus'

import { getHelpFormValue } from '@/constants'

import { setLSItem, getLSItem } from '@/utils/localStorage'

export default {
  name: 'EmailVerification',
  components: { FeatureCarousel },
  data: () => ({
    resendEmailLoader: false,
    verifyOTPLoader: false,
    otp: '',
    methodId: null,
  }),
  computed: {
    ...mapGetters('user', ['emailVerificationRequired']),
    isOpen() {
      return this.emailVerificationRequired
    },
    heightGreaterThan850() {
      return this.$vuetify.breakpoint.height >= 850
    },
  },
  watch: {
    isOpen(val) {
      if (val) this.resendEmail()
    },
  },
  methods: {
    ...mapActions('user', ['logout', 'load']),
    contactSupport() {
      EventBus.$emit('open-help-form', getHelpFormValue('GENERAL_SUPPORT'), {
        description: `Hi. I need help with my email verification.`,
      })
    },
    resendEmail() {
      this.resendEmailLoader = true
      const userEmail = getLSItem('userEmail', false)
      this.$stytchHeadless.otps.email
        .send(userEmail, {
          expiration_minutes: 5,
        })
        .then((res) => {
          console.log('magic link resent', res)
          this.methodId = res.method_id
          this.$store.commit('snackbar/setSnack', {
            snack: `Link sent to <strong>${userEmail}</strong>`,
            snackType: 'success',
          })
        })
        .finally(() => (this.resendEmailLoader = false))
    },
    verifyOTP() {
      this.verifyOTPLoader = true
      this.$stytchHeadless.otps
        .authenticate(this.otp, this.methodId, {
          session_duration_minutes: 60,
        })
        .then((data) => {
          console.log('OTP verified', data)
          setLSItem('stytchToken', data.session_jwt)

          this.$store.commit('user/store_login_token', {
            token: data.session_jwt,
            provider: 'stytch',
          })

          this.$store.commit('snackbar/setSnack', {
            snack: 'Verification succesful',
            snackType: 'success',
          })

          location.reload()
        })
        .finally(() => (this.verifyOTPLoader = false))
    },
  },
}
</script>
